<template>
  <div class="setting-biens-th">
    <div class="box-setting-header">
      <div class="title loaderPosition">
        <div class="title mr-4">Gestion des biens</div>
        <div v-if="loadingBien" class="loading">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          v-if="checkPermission('GBHTAB')"
          variant="success"
          class="config-btn ml-2"
          @click.prevent="handleModal('addBien')"
        >
          <font-awesome-icon icon="plus" /> Ajouter un bien
        </b-button>
      </div>
    </div>
    <div class="ligne-box-setting-header mt-2"></div>
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-end-position">
          <search
            v-if="checkPermission('GBHTCB')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            classToUse="search-style"
          />
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-biens-params"
          :items="biens"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneEnAttend"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-modifier-style ml-1"
              size="sm"
              variant="success"
              @click.prevent.stop="handleUpdate(data.item)"
              v-if="checkPermission('GBHTMB')"
              title="Modifier un bien"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              v-if="data.item.blocked == false && checkPermission('GBHTBDB')"
              class="button-warning-style-bien ml-1"
              size="sm"
              variant="warning"
              @click.prevent.stop="handleBlock(data.item)"
              title="Bloquer un bien"
            >
              <font-awesome-icon icon="eye" />
            </b-button>
            <b-button
              v-if="data.item.blocked && checkPermission('GBHTBDB')"
              class="button-warning-style-bien ml-1"
              size="sm"
              variant="warning"
              @click.prevent.stop="handleBlock(data.item)"
              title="Débloquer un bien"
            >
              <font-awesome-icon icon="eye-slash" />
            </b-button>
            <b-button
              class="button-duplicate-style ml-1"
              size="sm"
              variant="info"
              title="Dupliquer un bien"
              @click.prevent.stop="duplicateBien(data.item)"
              v-if="checkPermission('GBHTDB')"
            >
              <font-awesome-icon icon="copy" />
            </b-button>
          </template>
          <template v-slot:cell(nom)="data">
            {{ data.item.nom }}
          </template>
          <template v-slot:cell(adresse)="data">
            {{ data.item.adresse ? data.item.adresse : '-' }}
          </template>
          <template v-slot:cell(description)="data">
            {{ data.item.description ? data.item.description : '-' }}
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="totalRowsBien"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="addBien"
      id="addBien"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap modal-dialog-bien"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter un bien</div>
        <div class="iconClose" @click.prevent="hideModal('addBien')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style visibilty-bien"
            @submit.prevent.stop="submitAddBien"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="nom"
                v-model="bienToAdd.nom"
                required
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorNomBien" class="error">
                <ul
                  v-if="Array.isArray(errorNomBien)"
                  class="mb-0"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorNomBien" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorNomBien }}</div>
              </div>
            </div>

            <b-form-group
              label="Adresse"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="adresse"
                row="2"
                v-model="bienToAdd.adresse"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label="Description"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="description"
                row="2"
                v-model="bienToAdd.description"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="errorBiens" class="error">
                  <ul
                    v-if="Array.isArray(errorBiens)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in errorBiens" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ errorBiens }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loadingBien"
              >
                <span>
                  Valider
                  <div v-if="loadingBien" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="updateBien"
      id="updateBien"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap modal-dialog-bien"
    >
      <div class="hader mb-2" v-if="bienToUpdate">
        <div class="titleSetting">Modifer un bien {{ bienToUpdate.nom }}</div>
        <div class="iconClose" @click.prevent="hideModal('updateBien')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body v-if="bienToUpdate && bienToUpdate != null">
          <form
            class="form-modal-custom-style visibilty-bien"
            @submit.prevent.stop="submitUpdateBien"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="nom"
                v-model="bienToUpdate.nom"
                required
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorNomBien" class="error">
                <ul
                  v-if="Array.isArray(errorNomBien)"
                  class="mb-0"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorNomBien" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorNomBien }}</div>
              </div>
            </div>
            <div class="messageError">
              <div v-if="errorBiens" class="error">
                <ul
                  v-if="Array.isArray(errorBiens)"
                  class="mb-0"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorBiens" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorBiens }}</div>
              </div>
            </div>
            <b-form-group
              label="Adresse"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="adresse"
                row="2"
                v-model="bienToUpdate.adresse"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label="Description"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="description"
                row="2"
                v-model="bienToUpdate.description"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loadingBien"
              >
                <span>
                  Valider
                  <div v-if="loadingBien" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="duplicateBien"
      id="duplicateBien"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap modal-dialog-bien"
    >
      <div class="hader mb-2" v-if="bienToUpdate">
        <div class="titleSetting">Dupliquer un bien {{ bienToUpdate.nom }}</div>
        <div class="iconClose" @click.prevent="hideModal('duplicateBien')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body v-if="bienToUpdate && bienToUpdate != null">
          <form
            class="form-modal-custom-style visibilty-bien"
            @submit.prevent.stop="submitDuplicateBien"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="nom"
                v-model="bienToUpdate.nom"
                required
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorNomBien" class="error">
                <ul
                  v-if="Array.isArray(errorNomBien)"
                  class="mb-0"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorNomBien" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorNomBien }}</div>
              </div>
            </div>
            <div class="messageError">
              <div v-if="errorBiens" class="error">
                <ul
                  v-if="Array.isArray(errorBiens)"
                  class="mb-0"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorBiens" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorBiens }}</div>
              </div>
            </div>
            <b-form-group
              label="Adresse"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="adresse"
                row="2"
                v-model="bienToUpdate.adresse"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label="Description"
              label-for="description"
              class="input-modal-champ"
            >
              <b-form-textarea
                id="description"
                row="2"
                v-model="bienToUpdate.description"
                style="border-radius: 17px; width: 97%"
              ></b-form-textarea>
            </b-form-group>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loadingBien"
              >
                <span>
                  Valider
                  <div v-if="loadingBien" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      ListDate: [
        { value: null, text: 'Date courant' },
        { value: '0', text: 'Fin du mois' },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
        { value: '9', text: '9' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
        { value: '24', text: '24' },
        { value: '25', text: '25' },
        { value: '26', text: '26' },
        { value: '27', text: '27' },
        { value: '28', text: '28' },
        { value: '29', text: '29' },
        { value: '30', text: '30' },
        { value: '31', text: '31' },
      ],
      fields: [
        { key: 'nom', label: 'Nom' },
        { key: 'adresse', label: 'Adresse' },
        { key: 'description', label: 'Description' },
        {
          key: 'Actions',
          label: 'Actions',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation',
        },
      ],
      bienToAdd: {
        nom: null,
        adresse: null,
        description: null,
      },
      loading: false,
      bienToUpdate: null,
      oldBienToUpdate: null,
      locataireToDelete: null,
      id_bien: null,
      searchValue: null,
    };
  },
  methods: {
    ...mapActions([
      'getAllBiens',
      'getAllFilialesBiensListe',
      'createBien',
      'updateBlockedBien',
      'updateBien',
      'resetState',
      'updateLocataireBien',
      'updateBlockedLocataire',
      'deleteLocataireBien',
      'resetState',
    ]),
    rowDetailsClass(index) {
      if (index % 2 == 0) {
        return 'tdvalidation';
      } else {
        return 'ligneEnAttend';
      }
    },
    handleDeleteLocataire(data, item) {
      this.id_bien = data.id;
      this.locataireToDelete = item;
      this.$refs['DeleteLocataire'].show();
    },
    async submitDuplicateBien() {
      let bodyFormData = new FormData();
      if (this.bienToUpdate.nom != null || this.bienToUpdate.nom != '') {
        bodyFormData.append('nom', this.bienToUpdate.nom);
      }
      if (this.bienToUpdate.description != null) {
        bodyFormData.append('description', this.bienToUpdate.description);
      }
      if (this.bienToUpdate.adresse != null) {
        bodyFormData.append('adresse', this.bienToUpdate.adresse);
      }
      const response = await this.createBien(bodyFormData);
      if (response) {
        this.hideModal('duplicateBien');
        this.resetState();
      }
    },
    async submitUpdateBien() {
      let bodyFormData = new FormData();
      bodyFormData.append('id', this.bienToUpdate.id);
      if (this.bienToUpdate.nom != null) {
        bodyFormData.append('nom', this.bienToUpdate.nom);
      }
      if (this.bienToUpdate.description != null) {
        bodyFormData.append('description', this.bienToUpdate.description);
      }
      if (this.bienToUpdate.adresse != null) {
        bodyFormData.append('adresse', this.bienToUpdate.adresse);
      }
      let payload = {
        oldBienToUpdate: this.oldBienToUpdate,
        bienToUpdate: bodyFormData,
      };
      const response = await this.updateBien(payload);
      if (response) {
        this.hideModal('updateBien');
        this.resetState();
      }
    },
    handleUpdate(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      this.handleModal('updateBien');
    },
    duplicateBien(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      this.handleModal('duplicateBien');
    },
    async handleBlock(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      if (this.bienToUpdate.blocked == true) {
        let payload = {
          blocked: 0,
          id: this.bienToUpdate.id,
          item: this.oldBienToUpdate,
        };
        const response = await this.updateBlockedBien(payload);
        if (response) {
          this.resetModal();
        }
      } else {
        let payload = {
          blocked: 1,
          id: this.bienToUpdate.id,
          item: this.oldBienToUpdate,
        };
        const response = await this.updateBlockedBien(payload);
        if (response) {
          this.resetModal();
        }
      }
    },
    resetModal() {
      this.bienToAdd = {
        nom: null,
        description: null,
        adresse: null,
      };
      this.loading = false;
      this.bienToUpdate = {};
      this.oldBienToUpdate = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.resetState();
    },
    handleModal(ref) {
      this.resetState();
      this.$refs[ref].show();
    },
    async submitAddBien() {
      let bodyFormData = new FormData();
      if (this.bienToAdd.nom != null || this.bienToAdd.nom != '') {
        bodyFormData.append('nom', this.bienToAdd.nom);
      }
      if (this.bienToAdd.adresse != null) {
        bodyFormData.append('adresse', this.bienToAdd.adresse);
      }
      if (this.bienToAdd.description != null) {
        bodyFormData.append('description', this.bienToAdd.description);
      }
      const response = await this.createBien(bodyFormData);
      if (response) {
        this.hideModal('addBien');
        this.resetState();
      }
    },

    async handleFilter() {
      await this.getAllBiens({
        per_page: this.perPage,
        page: this.page,
        nom: this.searchValue,
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getAllBiens({
        per_page: this.perPage,
        page: this.page,
      });
    },
    async changePerPage() {
      await this.getAllBiens({
        per_page: this.perPage,
        page: this.page,
      });
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
  },
  computed: {
    ...mapGetters([
      'errorBiens',
      'errorNomBien',
      'loadingBien',
      'biens',
      'getFilialesBiens',
      'totalRowsBien',
      'checkPermission',
    ]),
  },
  components: {
    Card: () => import('../component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
  },
  mounted() {
    this.getAllFilialesBiensListe();
    this.handleFilter();
  },
};
</script>

<style scoped lang="scss">
.table-biens-params {
  max-height: calc(100vh - 280px) !important;
  height: calc(100vh - 280px) !important;
  margin-bottom: 0px;
  .button-danger-style {
    background-color: #dc3c3c;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 15px;
    border: none;
  }
  .button-warning-style-bien {
    background-color: #ffc107;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 11px;
    border: none;
  }
  .button-duplicate-style {
    background-color: #17a2b8;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 15px;
    border: none;
  }
  .button-modifier-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 15px;
    border: none;
  }
}

.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}

.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}

.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
</style>

<style lang="scss">
.error-messages {
  list-style: none;
  color: red;
  padding: 0px;
  font-size: 14px;
}
.table-biens-params {
  .thvalidation,
  .tdvalidation {
    min-width: 140px !important;
  }
}
.bien-block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 90%;
  border-radius: 10px;
  margin: 0px auto;
}
.table-biens-params {
  .color-row-bien {
    min-width: 42px;
  }
  .row-action-bien {
    min-width: 107px !important;
  }
  .item-details-style {
    border: none;
  }
}
.input-modal-champ div input {
  height: 30px;
  border-color: #70707038;
  border-radius: 17px;
  width: inherit;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
